<template>
  <v-navigation-drawer
    id="core-navigation-drawer" style="background-color:#878282;"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs">

    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

   <v-divider  />
   <div style="text-align:left; height:160px;margin-top:32px;">
<img style="margin-left:20%" src="../../../../assets/lg_grupo.png" width="60%" >
   </div>
    <v-divider class="mb-2" />
<!--
<v-img 
            class="c-sidebar-brand-full" 
            src="../../../../assets/logo_tamojunto.png"
            name="logo" 
            :height="160" 
            viewBox="0 0 506 134"/>
 -->
    <v-list 
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
 
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

     <v-divider class="mb-2" />

   
    
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      promotores: [],
      items: [
        {
          title: 'Usuário',
        },
        {
          icon: 'mdi-file',
          title: 'Documentos',
          to: '/dashboard',
        },

        {
          icon: 'mdi-upload',
          title: 'Upload',
          to: 'upload',
        },

         {
          icon: 'mdi-history',
          title: 'Histórico',
          to: 'historico',
        },

        {
          icon: 'mdi-key',
          title: 'Alterar Senha',
          to: 'register',
        },
       

        

         {
          title: 'Administrador',
        },
       
        {
          icon: 'mdi-account',
          title: 'Usuário',
          to: 'usuario',
        },
        

        {
          icon: 'mdi-logout',
          title: 'Sair',
          to: 'login'
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
        //  return true;
          //alert('.....'+this.$store.state.drawer)
          return this.$store.state.drawer
        },
        set (val) {
                    //alert(val)
          this.$store.commit('SET_DRAWER', val)
        }
      },

      computedItems () {
        this.ranking()
        return this.items.map(this.mapItem)
      },
     
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

  

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },

      ranking(){
          
      },

      logout(){
          sessionStorage.removeItem('user')
          to: login;
      },

     
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
